import React from 'react';
import main_text from './main_text.svg';
import logo from './logo.svg'
import left_decor from './left_decor.svg'
import right_decor from './right_decor.svg'
import vendor from './vendor.svg'
import location from './location.svg'
import insta from './insta.svg'
import './App.css';

function App() {

  const handleLocationClick = () => {
    window.open('https://calendly.com/getbossmarket/30min');
  };

  const handleVendorClick = () => {
    const to = 'Getbossmarket@gmail.com';
    const subject = 'Become a vendor';
    const emailLink = `mailto:${to}?subject=${subject}&body=`;
    window.location.href = emailLink;
  };

  return (
    <>
    <div>
      <img src={logo} className="App-logo" alt="logo" />
      <img src={right_decor} className="Right-decor" alt="right-decor" />
      <img src={left_decor} className="Left-decor" alt="left-decor" />
      <a href="https://www.instagram.com/getbossmarket/" target="_blank" rel="noreferrer">
        <img src={insta} className="Insta-follow" alt="insta" />
      </a>
    </div>
    <div className="logo-container" >
      <img src={main_text} className="App-text" alt="logo" />
      <div className='button'>
        <button className='button-main button-black' onClick={handleVendorClick}>
          <img src={vendor} alt="vendor"></img>
          <span>Become a vendor</span>
        </button>
        <button className='button-main button-white' onClick={handleLocationClick}>
          <img src={location} alt="location"></img>
          <span>Have a location</span>
        </button>
      </div>
    </div>
    </>
  );
}

export default App;
